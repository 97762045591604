import React from 'react'
import Loadable from 'react-loadable'

function Loading() {
  return <div>Loading...</div>
}

const Course = Loadable({
  loader: () => import('./views/Course/course'),
  loading: Loading,
})

const Topic = Loadable({
  loader: () => import('./views/Course/layout'),
  loading: Loading,
})

const PracticeTest = Loadable({
  loader: () => import('./views/PracticeTest/layout'),
  loading: Loading,
})

const routes = [
  {
    path: '/', exact: true, name: 'Home', component: Course,
  },
  {
    path: '/:id', exact: true, name: 'Course', component: Topic,
  },
  {
    path: '/practice/:id', exact: true, name: 'Practice', component: PracticeTest,
  },
]

export default routes
