import React, { Component } from 'react'
import {
  Redirect, Route, Switch, Link,
} from 'react-router-dom'
import {
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Form, FormGroup, Input,
} from 'reactstrap'
import ReactQuill, { Quill } from 'react-quill'
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from '@coreui/react'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import navigation from '../../_nav'
import { URL_WEBHOOK } from '../../constants'
// sidebar nav config
// routes config
import routes from '../../routes'
import DefaultAside from './DefaultAside'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'

class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies(document.cookie)
    this.state = {
      user: cookies.get('USER'),
    }
  }

  render() {
    const {
      user,
    } = this.state
    if (!user) return <Redirect to="/login" />
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader handleChangeTestMode={this.handleChangeTestMode} />
        </AppHeader>
        <div className="app-body">
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => route.component ? (
                  <Route
                    key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => (
                      <route.component {...props} />
                    )}
                  />
                )
                  : (null))}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    )
  }
}

export default DefaultLayout
