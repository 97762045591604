/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Badge,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Form, FormGroup, Input,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import Cookies from 'universal-cookie'
import {
  Link, Redirect, Route, Switch,
} from 'react-router-dom'

import ReactQuill, { Quill } from 'react-quill'
import Swal from 'sweetalert2'
// sidebar nav config
// routes config
import {
  ApolloClient,
  InMemoryCache,
  gql,
} from '@apollo/client'
import 'react-quill/dist/quill.snow.css'
import $ from 'jquery'
import { URL_WEBHOOK, URL_HASURA } from '../../constants'

const axios = require('axios')

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video', 'align',
  'alt',
  'height',
  'width',
  'style',
]

class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    const cookies = new Cookies(document.cookie)
    this.state = {
      user: cookies.get('USER'),
    }
  }

  render() {
    // eslint-disable-next-line
    const { children, isTest, ...attributes } = this.props
    const {
      isOpenModal,
      title,
      description,
      willLearn,
      thumbnailPlaceholder,
    } = this.state
    const cookies = new Cookies(document.cookie)
    const user = cookies.get('USER')
    return (
      <>
        <div
          id="pop-up-box" style={{
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            width: '100%',
            top: 120,
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.5s linear',
          }}
        >
          <div
            id="pop-up-message" style={{
              padding: '10px 25px',
              background: 'rgb(0, 153, 51, 0.7)',
              borderRadius: 10,
              color: 'rgb(255, 255, 255, 0.7)',
            }}
          >
            <i
              className="fa fa-check" style={{
                marginRight: 10,
                color: 'rgb(0, 153, 51, 0.7)',
                background: 'rgb(255, 255, 255, 0.7)',
                padding: 5,
                borderRadius: 50,
                border: '1px solid rgb(221, 221, 221, 0.7)',
              }}
            />
            The content has been updated
          </div>
        </div>
        <AppNavbarBrand
          href="/"
          style={{ marginLeft: 15 }}
          full={{
            src: '/logod.png', height: 30, alt: 'Finademy Logo',
          }}
        />
        <a
          href="/" style={{
            color: '#fff', fontSize: 18, fontWeight: 600, textDecoration: 'none',
          }}
        >
          LC’s Learning System
        </a>
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink href="#">
              Welcome,
              {' '}
              {user.user.username}
            </NavLink>
          </NavItem>
          <NavItem className="d-md-down-none" style={{ margin: '0px 10px' }}>
            <NavLink
              href="/login" onClick={() => {
                cookies.remove('USER')
              }}
            >
              <img className="sign-out" src="/sign-out.png" alt="Sign Out" width={24} height={24} />
            </NavLink>
          </NavItem>
        </Nav>
      </>
    )
  }
}

export default DefaultHeader
